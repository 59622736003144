export const FreeTrialMessage = (function() {

    const HIDE_MESSAGE_COOKIE_NAME  = 'hide_free_trial_alert';
    const SHOWN_MESSAGE_COOKIE_NAME = 'shown_free_trial_alert';

    const exports = {};

    exports.init = function() {

        if (isNotCookieSet(SHOWN_MESSAGE_COOKIE_NAME)) {
            setMessageShownCookie();
            trackMessageShown();
        }

        $(document)
            .on('click', '#free_trial_alert [data-dismiss="tl-alert"]', function() {
                if (isNotCookieSet(HIDE_MESSAGE_COOKIE_NAME)) {
                    setMessageClosedCookie();
                }

                trackCloseClick();
            })
            .on('click', '#free_trial_alert_cta', function() {
                trackCtaClick();
            });
    }

    function isCookieSet(cookieName) {
        return document.cookie.indexOf(cookieName) !== -1;
    }

    function isNotCookieSet(cookieName) {
        return document.cookie.indexOf(cookieName) === -1;
    }

    function setMessageClosedCookie() {
        if (isCookieSet(HIDE_MESSAGE_COOKIE_NAME)) {
            return;
        }

        // show message again next day
        // cookie also clears on logout on the server
        const domain = window.this_site_domain ?? window.location.hostname;
        const date = new Date();

        date.setDate(date.getDate() + 1);

        document.cookie = `${HIDE_MESSAGE_COOKIE_NAME}=1; expires=${date.toUTCString()}; path=/; domain=${domain}`;
    }

    function setMessageShownCookie() {
        if (isCookieSet(SHOWN_MESSAGE_COOKIE_NAME)) {
            return;
        }

        // track once a day
        const domain = window.this_site_domain ?? window.location.hostname;
        const date = new Date();

        date.setDate(date.getDate() + 1);

        document.cookie = `${SHOWN_MESSAGE_COOKIE_NAME}=1; expires=${date.toUTCString()}; path=/; domain=${domain}`;
    }

    function trackCloseClick() {
        if (!window.Analytics || !window.Analytics.track) {
            return;
        }

        const EVENT_ID = 323;
        let eventData = window.location.pathname;

        window.Analytics.track('14|0a. Marketing', {
            type_id: EVENT_ID,
            eventData: {
                "event_cat_id": 14,
                "event_cat_name": "0a. Marketing",
                "tl_event_id": EVENT_ID,
                "tl_event_name": '(Free Trial Message) Closed Message',
                "tl_event_data": eventData
            },
            localData: {
                datavar: eventData
            }
        });
    }

    function trackCtaClick() {
        if (!window.Analytics || !window.Analytics.track) {
            return;
        }

        const EVENT_ID = 324;
        let eventData = window.location.pathname;

        window.Analytics.track('14|0a. Marketing', {
            type_id: EVENT_ID,
            eventData: {
                "event_cat_id": 14,
                "event_cat_name": "0a. Marketing",
                "tl_event_id": EVENT_ID,
                "tl_event_name": '(Free Trial Message) CTA Clicked',
                "tl_event_data": eventData
            },
            localData: {
                datavar: eventData
            }
        });
    }

    function trackMessageShown() {
        if (!window.Analytics || !window.Analytics.track) {
            return;
        }

        const EVENT_ID = 325;
        let eventData = window.location.pathname;

        window.Analytics.track('14|0a. Marketing', {
            type_id: EVENT_ID,
            eventData: {
                "event_cat_id": 14,
                "event_cat_name": "0a. Marketing",
                "tl_event_id": EVENT_ID,
                "tl_event_name": '(Free Trial Message) Shown Message',
                "tl_event_data": eventData
            },
            localData: {
                datavar: eventData
            }
        });
    }

    return exports;
})();