/**
 * Created by akshay on 4/13/16.
 */

window.framework = (function () {
    var exports = {};

    exports.init = function () {



    };

    exports.screen = function(){
        var myWidth = 0, myHeight = 0;
        if( typeof( window.innerWidth ) == 'number' ) {
            //Non-IE
            myWidth = window.innerWidth;
            myHeight = window.innerHeight;
        } else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) {
            //IE 6+ in 'standards compliant mode'
            myWidth = document.documentElement.clientWidth;
            myHeight = document.documentElement.clientHeight;
        } else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) {
            //IE 4 compatible
            myWidth = document.body.clientWidth;
            myHeight = document.body.clientHeight;
        }
        return {
            height: myHeight,
            width: myWidth
        }
    };

    exports.get = function(u, d, s, e, p){
        framework.request('GET',u,d,s,e,p);
    };

    exports.post = function(u, d, s, e,p){
        framework.request('POST',u,d,s,e,p);
    };

    exports.load = function(u, d, s, e,p){
        //console.debug("Web Request: "+u,d);
        $.ajax({
            type: "GET",
            url: u,
            data: d,
            cache: false,
            success: function($html) {
                if(typeof s === 'function') {
                    s($html);
                }
            },
            error: function(xhr, error) {
                $('body').append(xhr.responseText);
                if(typeof e == 'function') {
                    //e('AjaxError',d);
                    e(new Error('AjaxError'));
                }
            }
        });
    };

    exports.modal = function(u, d, s, e){
        framework.load(u,d,function(h){
            if($('#'+ d.container).length > 0){
                $('#'+ d.container).remove();
            }
            $('body').append("<div id='"+ d.container +"'></div>");
            $('#'+d.container).html(h);
            if(typeof s === 'function') {
                s(h);
            }
        },e);
    };

    exports.request = function(m, u, d, s, e, p){
        //console.debug("Web Request: "+u,d);
        var pr = p || {};
        var fn = {
            type: m,
            url: u,
            data: d,
            cache: false,
            success: function($html) {
                var $response = {};
                try {
                    if(typeof $html == 'string') {
                        $html = $html.replace(/\n/g, '');
                        $response = $.parseJSON($html);
                    } else if(typeof $html == 'object') {
                        $response = $html;
                    } else {
                        e('JsonParseError',$html);
                        return;
                    }

                } catch(err) {
                    e('JsonParseError',err);
                    return;
                }
                //console.debug("Web Response: "+ u,$response);
                if($response !== null && $response.hasOwnProperty('returnCode')) {

                    if($response.returnCode == 0 && typeof s === 'function') {
                        s($response);
                    } else {
                        //Failure
                        if(typeof e == 'function') {
                            e('RequestFailed',$response);
                        }
                    }
                    // Success
                } else  {
                    //console.debug("Third Party")
                    if(typeof s === 'function') {
                        s($response);
                    }
                }

            },
            error: function(xhr, error) {
                if(typeof e == 'function') {
                    //e('AjaxError',d);
                    e(new Error('AjaxError'));
                }
            }
        };
        $.extend(fn,pr);
        $.ajax(fn);

    };

    exports.clean = function(val){
        if(typeof val == 'undefined' || val == "") {
            return "";
        }
		
		var hasHtml = /<[a-z][\s\S]*>/i.test(val);
		
		if(!hasHtml){
        	val = val.replace(/[`\{\}\\\<\>]/gi,'');
       	 	val = val.replace(/\n/gi, "<br/>");
       	 	val = val.replace(/'/gi,'&rsquo;');
        	val = val.replace(/"/gi,'&rdquo;');
		} 
		
		return val;
    };

    exports.removeHTML = function(val){
        if(!val || val == ""){
            return val;
        }
        val = val.replace(/<br\s*[\/]?>/gi, "\n");
        val = val.replace(/\&rsquo\;/gi,"'");
        val = val.replace(/\&rdquo\;/gi,'"');
        return val;
    };

	exports.strip = function(html){
	   var tmp = document.createElement("DIV");
	   tmp.innerHTML = html;
	   return tmp.textContent || tmp.innerText || "";
	}

    return exports;

})();